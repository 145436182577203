.hero {
    width: 100vw;
    height: 70vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1 {
    padding-top: 4rem !important;
    align-items: center;
    font-weight: 700;
}

.hero-track {
    width: 100%;
    height: 60vh;
    position: relative;
}

.hero-contact {
    width: 100%;
    height: 60vh;
    margin-top: 3rem;
    position: relative;
}

.hero-support {
    width: 100%;
    height: 50vh;
    margin-top: 5rem;
    position: relative;
}


img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 40%;
    transform: translate(-40%, -50%);
    width: 60%;
    text-align: center;
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: 700;
    background: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p {
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #000;
    transition: 0.2s ease-in-out;
}

.hero-text .show:hover {
    /* color: #FFF; */
    /* background-color: #000; */
    color: var(--secondary-color);
    background-color: var(--primary-color);
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .hero-text h1 {
        font-size: 2.7rem;
    }

}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .hero-text h1 {
        font-size: 2.4rem;
    }

}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .hero-text h1 {
        font-size: 2.4rem;
    }

}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .hero-text h1 {
        font-size: 2.2rem;
    }

    .hero-text .show {
        padding: 0.8rem 1.7rem;
        font-size: 0.8rem;
    }

    .hero-text p {
        font-size: 1.3rem;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .hero {
        height: 60vh;
    }

    .hero-text h1 {
        font-size: 1.9rem;
    }

    .hero-text p {
        font-size: 1rem;
    }

    .hero-text .show {
        padding: 0.5rem 1.3rem;
        font-size: 0.6rem;
    }

}


@media only screen and (max-width: 485px) {
    .hero {
        height: 60vh;
    }

    .hero-text h1 {
        font-size: 1.7rem;
    }

    .hero-text p {
        font-size: 1rem;
    }

    .hero-text .show {
        padding: 0.3rem 1.1rem;
        font-size: 0.6rem;
    }

}