.noPageFound-hero {
    margin-top: 8rem;
    padding: 3rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #eee;
}

.noPageFound-hero-content {
    width: 48%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
}

.noPageFound-hero-image {
    width: 48%;
}