.footer {
    padding: 3rem 6rem 0 6rem;
    /* background-color: #000; */
    /* background-color: var(--primary-color); */
    /* background-color: #2857A5; */
    background-color: #06214d;
    color: #fff;
}

.top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: start;
}

.top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover {
    color: #ee7904;
}

.bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    text-align: start;
    flex-wrap: wrap;
}

.bottom .bottom-div {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
}

.bottom h4 {
    font-size: 1.3rem;
    padding: 1rem 0 0.8rem 0;
}

.bottom a {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}

.bottom-last {
    /* background-color: #01959a; */
    /* padding-bottom: 1rem; */
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-texts {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-texts p,
.footer-texts i {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}

@media screen and (max-width:850px) {
    .footer {
        padding: 2rem;
    }

    .footer i {
        margin: 1rem 1rem 0 0;
    }

    .bottom div {
        width: 50%;
    }
}

@media only screen and (min-width: 485px) and (max-width: 600px) {
    .footer {
        padding: 2rem;
    }

    .footer i {
        margin: 1rem 1rem 0 0;
    }

    .bottom div {
        width: 50%;
    }

    .bottom h4 {
        font-size: 1rem;
        padding: 1rem 0 0.8rem 0;
    }

    .bottom a {
        font-size: 0.8rem;
    }

    .bottom p {
        font-size: 0.8rem;
    }

    .top i {
        font-size: 1.5rem;
    }

    .bottom-last h4 {
        font-size: 20px;
    }
}


@media only screen and (max-width: 485px) {
    .footer {
        padding: 2rem 0.5rem 0.7rem 0.5rem;
    }

    .footer i {
        margin: 1rem 1rem 0 0;
    }

    .footer-texts {
        gap: 0.5rem;
    }

    .bottom div {
        width: 80%;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .bottom {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bottom div {
        display: flex;
        padding-bottom: 1rem;
    }

    .bottom-last {
        padding: 1rem;
    }

    .bottom-last h4 {
        font-size: 18px;
    }

    .bottom-div a {
        font-size: 14px;
    }

    .bottom-div p {
        font-size: 14px;
    }
}