.shipment-item-div {
    margin: 1rem 14rem;
}

.shipment-item-div-heading {
    text-align: center;
}

.shipment-main-div {
    padding: 2rem 1rem;
}

.shipment-shoping-cart {
    background-color: rgb(219, 228, 225);
}

.shipment-product-table {
    margin: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
}

.shipment-main-heading {
    width: 100%;
    padding: 1rem 2rem;
}

.shipment-products {
    padding: 1rem;
}

.shipment-products-content {
    padding-top: 10px;
}

.shipment-action-buttons {
    padding: 1rem 2rem;
    display: flex;
    margin: 0 1rem;
    background-color: rgb(255, 255, 255);
}

.shipment-products-content p {
    font-size: 12px;
    display: flex;
    align-items: flex-start;
}

.shipment-action-buttons p {
    padding: 0.5rem 2rem 0.5rem 0;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.shipment-action-buttons p:last-child {
    color: red;
}

.shipment-amount-text {
    padding: 0 2rem;
}

.shipment-total-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 1rem;
    border-top: 2px solid blue;
    background-color: rgb(177, 226, 255);
}

.shipment-payment-method {
    background-color: rgb(255, 255, 255);
    margin: 0 1rem;
    /* padding: 1rem 2rem; */
}

.shipment-amount-number {
    padding-right: 10rem;
}

.shipment-payment-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 0.5px solid #7a7a7a;
}

.shipment-payment-row p {
    font-size: 18px;
    font-weight: 500;
}

.shipment-payment-row-img {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
}

.shipment-payment-row-img img {
    /* width: 70px;
    height: 30px; */
    object-fit: contain;
}

.shipment-terms-and-condition-text {
    background-color: rgb(255, 255, 255);
    padding: 2rem 4rem;
    margin: 1rem;
}

.shipment-final-payment {
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shipment-final-payment-button {
    background-color: rgb(14, 14, 233);
    cursor: pointer;
    height: 60px;
    padding: 1rem 5rem;
    color: #fff;
    border: none;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
}

.shipment-final-payment-button p {
    padding: 2rem;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.shipment-white-space {
    height: 10px;
}

.shipment-final-payment-button:hover {
    background-color: rgb(13, 13, 114);
}

/* -------------------CheckBox------------------------ */

.custom-checkbox {
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    display: inline-block;
    line-height: 1.5;
}

/* Hide the default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Styles for the custom checkbox design */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #eee;
    border: 1px solid #aaa;
}

/* Show a checkmark when the checkbox is checked */
.custom-checkbox input:checked+.checkmark:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* ------------------------Modal------------------------------ */


body.active-modal {
    overflow-y: hidden;
}

.payment-modal,
.payment-overlay {
    width: 100vw;
    height: 100vh;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.payment-overlay {
    background: rgba(49, 49, 49, 0.8);
}

.payment-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 8px;
    max-width: 600px;
    min-width: 300px;
}

.payment-close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.payment-show-tracking-number {
    padding: 2rem 4rem;
    background-color: rgb(253, 202, 107);
    border-radius: 10px;
}

.payment-modal-content-heading {
    padding: 2rem;
}

.payment-tracking-number {
    font-size: 2rem;

}

.payment-tracking-number-text {
    font-size: 1rem;
    padding: 2rem 4rem;
    text-align: center;
}

.payment-tracking-number-warning {
    font-size: 1rem;
    color: blue;
    padding: 1rem 4rem;
    text-align: center;
}

.payment-close-modal i {
    font-size: 2rem;
    color: red;
    cursor: pointer;
}

.payment-verifying-warning {
    font-size: 1.3rem;
    color: rgb(255, 0, 0);
    padding: 1rem 4rem;
    text-align: center;
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.verify-loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.loading-spin {
    background: #fff;
}



/* -----------------------------------Media query---------------- */


@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .shipment-item-div {
        margin: 1rem 10rem;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .shipment-item-div {
        margin: 1rem 7rem;
    }

    .payment-modal-content-heading {
        padding: 1rem;
        font-size: 2.1rem;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .shipment-item-div {
        margin: 1rem 3rem;
    }

    .payment-modal-content-heading {
        padding: 1rem;
        font-size: 1.6rem;
    }

    .payment-show-tracking-number {
        padding: 2rem 3rem;
    }

    .payment-tracking-number-text {
        font-size: 1rem;
        padding: 2rem;
    }

    .payment-close-modal i {
        font-size: 1.5rem;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .shipment-item-div {
        margin: 1rem;
    }

    .shipment-products {
        padding: 0.5rem;
    }

    .shipment-action-buttons {
        margin: 0 1rem;
    }

    .shipment-main-heading {
        padding: 1rem;
    }

    .shipment-terms-and-condition-text {
        padding: 1.6rem;
    }

    .payment-modal-content-heading {
        padding: 1rem;
        font-size: 1.2rem;
    }

    .payment-verifying-warning {
        font-size: 1.2rem;
        padding: 1rem;
    }

    .payment-close-modal i {
        font-size: 1.3rem;
    }

    .payment-show-tracking-number {
        padding: 2rem;
    }

    .payment-tracking-number-text {
        padding: 1rem;
    }

    .payment-tracking-number-warning {
        padding: 1rem 2rem;
    }

    .notificationContainer {
        padding: 1rem 0;
    }

    .notificationBox p {
        font-size: 16px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .shipment-item-div {
        margin: 2rem;
    }

    .shipment-main-heading {
        padding: 1rem;
    }

    .shipment-product-table {
        margin: 1rem 1rem 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .shipment-products {
        padding: 0.4rem 3rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .shipment-action-buttons {
        margin: 0 1rem;
        padding: 1rem 3rem;
    }


    .shipment-terms-and-condition-text {
        padding: 1.6rem;
    }

    .shipment-amount-number {
        padding-right: 6rem;
    }

    .payment-modal-content-heading {
        padding: 1rem;
        font-size: 1.2rem;
    }

    .payment-verifying-warning {
        font-size: 1rem;
        padding: 1rem;
    }

    .payment-tracking-number-warning {
        font-size: 1rem;
        padding: 1rem;
    }

    .payment-close-modal i {
        font-size: 1.3rem;
    }

    .payment-show-tracking-number {
        padding: 2rem;
    }

    .payment-tracking-number-text {
        padding: 1rem;
    }

    .payment-tracking-number-warning {
        padding: 1rem 2rem;
    }

    .notificationContainer {
        padding: 1rem 0;
    }

    .notificationBox p {
        font-size: 14px;
    }

    .notificationBox {
        height: 60px;
        padding: 1rem;
    }

    .notificationBox i {
        font-size: 1.5rem;
        padding: 5px;
        margin-right: 10px;
    }

}


@media only screen and (max-width: 485px) {
    .shipment-item-div {
        margin: 0.5rem;
    }

    .shipment-main-heading {
        padding: 1rem;
    }

    .shipment-main-heading h3 {
        font-size: 14px;
    }



    .shipment-product-table {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .shipment-products {
        padding: 0.4rem 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }


    .shipment-action-buttons {
        margin: 0 1rem;
        padding: 1rem;
    }

    .shipment-action-button-remove {
        font-size: 12px;
    }

    .shipment-terms-and-condition-text {
        padding: 1.6rem;
    }

    .shipment-amount-number {
        padding-right: 1rem;
    }

    .shipment-payment-row {
        padding: 0.5rem;
    }

    .custom-checkbox {
        padding-left: 2rem;
    }

    .shipment-terms-and-condition-text {
        font-size: 12px;
    }

    .shipment-payment-row {
        font-size: 12px;
    }

    .shipment-final-payment-button {
        font-size: 13px;
    }

    .payment-modal-content-heading {
        padding: 1rem;
        font-size: 1.3rem;
    }

    .payment-modal-content {
        padding: 1rem;
    }

    .payment-tracking-number-warning {
        font-size: 0.8rem;
        padding: 1rem;
    }


    .payment-verifying-warning {
        font-size: 0.9rem;
        padding: 0.8rem;
    }

    .payment-close-modal i {
        font-size: 1.3rem;
    }

    .payment-show-tracking-number {
        padding: 1rem;
    }

    .payment-tracking-number-text {
        padding: 0.8rem;
        font-size: 0.9rem;
    }

    .notificationContainer {
        padding: 0;
    }

    .notificationBox p {
        font-size: 14px;
    }

    .notificationBox {
        height: 60px;
        padding: 1rem;
    }

    .notificationBox i {
        font-size: 1.5rem;
        padding: 5px;
        margin-right: 10px;
    }

}