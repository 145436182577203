.contact-form-container {
    display: flex;
    margin: 1rem;
    padding: 1rem;
    gap: 2rem;
}

.contact-form-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-texts i {
    font-size: 30px;
    color: #ff9401;
}

.contact-form-div-heading {
    font-weight: bold;
}

.contact-form-div-text {
    font-size: 16px;
}

.contact-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contact-email input,
.contact-email textarea {
    background: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.contact-email-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-button {
    border-radius: 20px;
    border: none;
    background: #ff9401;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 80ms ease-in;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.contact-button:hover {
    transform: translateY(-4px);
}

.contact-texts {
    display: flex;
    gap: 1rem;
}

.error-text {
    color: red;
    margin: 0;
}



@media only screen and (min-width: 600px) and (max-width: 768px) {
    .contact-form-container {
        margin: 0;
        padding: 0;
        gap: 1rem;
    }

    .contact-form-div-heading {
        font-size: 24px;
    }

    .contact-form-div-text {
        font-size: 14px;
    }

    .contact-texts i {
        font-size: 24px;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .contact-form-container {
        margin: 0;
        padding: 0;
        gap: 1rem;
    }

    .contact-form-div-heading {
        font-size: 24px;
    }

    .contact-form-div-text {
        font-size: 14px;
    }

    .contact-texts i {
        font-size: 24px;
    }
}


@media only screen and (max-width: 485px) {
    .contact-form-container {
        margin: 0;
        padding: 0;
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }

    .contact-form-div-heading {
        font-size: 28px;
    }

    .contact-form-div-text {
        font-size: 14px;
    }

    .contact-texts i {
        font-size: 24px;
    }
}