.super-form-div {
    margin: 1rem 10rem;
    padding: 1rem 4rem;
}

.input-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.input-label select,
.input-label input {
    margin: 5px 0;
    font-size: 16px;
    padding: 5px 10px;
}

.flex-inputs {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex-inputs .input-label input {
    width: 280px;
}

.phone-email-input {
    display: flex;
    align-items: center;
}

.phone-email-input .input-label {
    margin-right: 30px;
}

.phone-email-input .input-label input:first-child {
    margin-right: 30px;
    width: 460px;
}


.form-div-sender,
.form-div-reciver {
    padding-bottom: 2rem;
}

.form-div-sender h2,
.form-div-reciver h2 {
    padding: 1rem 0;
}

.form-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.continue-shipment {
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    /* background-color: rgb(255, 187, 0); */
    background-color: #ff9401;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.continue-shipment:hover {
    transform: translateY(-3px)
}

.cancel-Shipment {
    padding: 1rem 3rem;
    text-decoration: underline;
    text-align: center;
    color: blue;
    cursor: pointer;
}

.input-error {
    border-color: red;
}

.error-text {
    color: red;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .super-form-div {
        margin: 1rem 6rem;
        padding: 1rem 3rem;
    }

    .flex-inputs .input-label input {
        width: 240px;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 10px;
        width: 400px;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .super-form-div {
        margin: 1rem 6rem;
        padding: 1rem 3rem;
    }

    .flex-inputs {
        display: block;
        flex-direction: column;
    }

    .flex-inputs .input-label input {
        width: 100%;
    }

    .phone-email-input .input-label {
        margin-right: 10px;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 10px;
        width: 290px;
    }


}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .super-form-div {
        margin: 1rem 4rem;
        padding: 1rem 3rem;
    }

    .flex-inputs {
        display: block;
        flex-direction: column;
    }

    .flex-inputs .input-label input {
        width: 100%;
    }

    .phone-email-input .input-label {
        margin-right: 10px;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 10px;
        width: 300px;
    }


    .phone-email-input .input-label input {
        margin-right: 10px;
        width: 100%;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .super-form-div {
        margin: 1rem 2rem;
        padding: 1rem 3rem;
    }

    .flex-inputs {
        display: block;
        flex-direction: column;
    }

    .flex-inputs .input-label input {
        width: 100%;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
    }

    .phone-email-input .input-label {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input {
        margin-right: 10px;
        width: 100%;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .super-form-div {
        margin: 1rem 2rem;
        padding: 1rem 2rem;
    }

    .flex-inputs {
        display: block;
        flex-direction: column;
    }

    .flex-inputs .input-label input {
        width: 100%;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
    }

    .phone-email-input .input-label {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input {
        margin-right: 10px;
        width: 100%;
    }

    .form-div-sender h2,
    .form-div-reciver h2 {
        padding: 1rem 0;
        font-size: 17px;
    }
}


@media only screen and (max-width: 485px) {
    .super-form-div {
        margin: 1rem;
        padding: 1rem;
    }

    .flex-inputs {
        display: block;
        flex-direction: column;
    }

    .flex-inputs .input-label input {
        width: 100%;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
    }

    .phone-email-input .input-label {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input:first-child {
        margin-right: 0px;
        width: 100%;
    }

    .phone-email-input .input-label input {
        margin-right: 10px;
        width: 100%;
    }

    .form-div-sender h2,
    .form-div-reciver h2 {
        padding: 1rem 0;
        font-size: 17px;
    }

    .continue-shipment {
        font-size: 10px;
        padding: 0.3rem 0.8rem;
        border-radius: 20px;
    }

    .cancel-Shipment {
        padding: 0.5rem 1rem;
        font-size: 12px;
        margin-bottom: 0;
    }
}