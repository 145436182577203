.services-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    gap: 10px;
    margin: 2rem 0;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading .display-text {
    font-weight: 600;
    font-size: 30px;
    width: 180px;
    line-height: 41px;
    text-align: center;
}

.content img {
    width: 70px;
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .services-section {
        padding: 1rem;
        gap: 0;
        margin: 0;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 30px;
        width: 100%;
        padding: 1rem;
    }

    hr {
        margin: 0;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .services-section {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 30px;
        width: 100%;
        padding: 2rem;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .services-section {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 2rem 1rem;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 30px;
        width: 100%;
        padding: 1rem;
    }

    hr {
        margin: 10px;
    }

    .content img {
        width: 60px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .services-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 0;
        padding: 2rem 1rem;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 30px;
        width: 100%;
        padding: 1rem;
    }

    hr {
        margin: 10px;
    }

    .content {
        gap: 5px;
    }

    .content img {
        width: 60px;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 20px;
        width: 100%;
        padding: 1rem;

    }
}


@media only screen and (max-width: 485px) {
    .services-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 0;
        padding: 2rem 1rem;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        width: 100%;
        padding: 1rem;
    }

    hr {
        margin: 10px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .content img {
        width: 60px;
    }

    .heading .display-text {
        font-weight: 600;
        font-size: 20px;
        width: 100%;
        padding: 1rem;
    }
}