.slider-heading-one {
    font-size: 4.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
}

.slider-heading-two {
    font-size: 4.5rem;
    font-weight: bold;
    display: block;
}

.slider-img {
    display: block !important;
    width: 100% !important;
}

.btn {
    font-size: 1rem !important;
    padding: 0.375rem 0.75rem !important;
}

.carousel-btn {
    display: block;
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .slider-heading-one {
        font-size: 3.5rem;
    }

    .slider-heading-two {
        font-size: 3.5rem;
        font-weight: bold;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .slider-heading-one {
        font-size: 2.5rem;
    }

    .slider-heading-two {
        font-size: 2.5rem;
        font-weight: bold;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .slider-heading-one {
        font-size: 1.75rem;
    }

    .slider-heading-two {
        font-size: 1.75rem;
        font-weight: bold;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .slider-heading-one {
        font-size: 1rem;
    }

    .slider-heading-two {
        font-size: 1rem;
        font-weight: bold;
    }

    .btn {
        font-size: 0.75rem !important;
        padding: 0.3rem 0.65rem !important;
    }
}


@media only screen and (max-width: 485px) {
    .slider-heading-one {
        display: none;
    }

    .slider-heading-two {
        display: none;
    }

    .carousel-caption p {
        display: none;
    }

    .carousel-btn {
        display: none !important;
    }
}