.ship-item-div {
    margin: 1rem 10rem;
    padding: 1rem 4rem;
}

.item-div {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border: 2px solid #000
}

.item-package-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 2rem;
    background-color: darkgray;
}

.item-package-name h4,
.item-package-name i {
    padding: 0 0.5rem;
    color: #fff;
    font-size: 20px;
}

p {
    margin-bottom: 0 !important;
}

.item-sub-div {
    width: 100%;
    padding: 1rem 2rem;
}

.item-div-content {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.item-div-input-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-div-input-row p {
    padding: 0 1rem;
}

.input-label select,
.input-label input {
    margin: 5px 0;
    font-size: 16px;
    padding: 5px 10px;
}

.item-box-container {
    background-color: lightgray;
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.item-box-input {
    padding: 1rem;
}

.item-box-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-box-image img {
    width: 300px;
    height: auto;
    object-fit: contain;
    mix-blend-mode: color-burn;
}

.item-div-simple-text {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
}

/* ---------------------------Item Price----------------------- */

.item-total-display {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-top: 2px solid blue;
    background-color: rgb(177, 226, 255);
}


/* ..........................Schedule pick up------------- */


.shipment-detail-schedule {
    background-color: rgb(219, 228, 225);
    padding: 1rem 0;
    width: 100%;
}

.shipment-detail-heading {
    padding: 0 2rem;
}

.shipment-detail-heading-h3 {
    font-size: 34px;
    color: gray;
}

.shipment-detail-date {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.shipment-detail-date p {
    padding: 1rem;
}

.shipment-detail-date-picker {
    display: flex;
    align-items: center;
}

.shipment-detail-datepicker {
    width: 170px;
    height: 32px;
    padding: 5px;
    font-size: 15px;
}

.shipment-detail-date-picker i {
    padding-left: 1rem;
    font-size: 28px;
    color: rgb(0, 81, 128);
}

.shipment-detail-discription {
    margin: 0 1rem;
}

.input-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.input-label input {
    margin: 5px 0;
    font-size: 16px;
    padding: 5px 10px;
}

.shipment-detail-heading-discription {
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-error {
    border-color: red;
}

.error-text {
    color: red;
}


/* -----------------------button------------------ */

.item-div-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
}

.item-div-button-back {
    border: 3px solid blue;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 40px;
}

.item-div-button-continue {
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    /* background-color: rgb(255, 187, 0); */
    background-color: #ff9401;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
    margin: 0 2rem;
}

.item-div-button-continue:hover {
    transform: translateY(-3px)
}

.cancel-item-Shipment {
    padding: 1rem 3rem;
    text-decoration: underline;
    text-align: center;
    color: blue;
    cursor: pointer;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .ship-item-div {
        margin: 1rem 8rem;
        padding: 1rem 4rem;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .ship-item-div {
        margin: 1rem 6rem;
        padding: 1rem 4rem;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .ship-item-div {
        margin: 1rem 4rem;
        padding: 1rem 2rem;
    }

    .cancel-item-Shipment {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .ship-item-div {
        margin: 1rem 2rem;
        padding: 1rem;
    }

    .ship-item-div h2 {
        font-size: 24px;
    }


    .item-box-image img {
        width: 250px;
    }

    .item-div-simple-text {
        padding: 1rem;

    }

    .item-div-simple-text p {
        font-size: 14px;
    }

    .item-div-simple-text h3 {
        font-size: 20px;
    }

    .shipment-detail-heading {
        padding: 0 1rem;
        font-size: 20px;
    }

    .shipment-detail-date p {
        padding: 0rem;
    }

    .item-div-button {
        padding: 1rem;
    }

    .cancel-item-Shipment {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .ship-item-div {
        margin: 1rem 2rem;
        padding: 1rem;
    }

    .ship-item-div h2 {
        font-size: 18px;
    }

    .item-box-container {
        display: flex;
        flex-direction: column;
    }

    .item-box-input {
        padding: 1rem;
        width: 100%;
    }

    .item-div-input-row input {
        width: 170px;
    }

    .item-div-input-row p {
        font-size: 14px;
        padding: 0 0.3rem;
    }

    .item-div-simple-text {
        padding: 1rem;

    }

    .item-div-simple-text p {
        font-size: 12px;
    }

    .item-div-simple-text h3 {
        font-size: 18px;
    }

    .shipment-detail-heading {
        padding: 0 1rem;
    }

    .shipment-detail-heading h3 {
        font-size: 24px;
    }

    .shipment-detail-date p {
        padding: 0rem;
    }

    .shipment-detail-heading-discription h3 {
        font-size: 18px;
    }

    .item-div-button {
        padding: 1rem;
    }

    .item-div-button {
        padding: 1rem;
    }

    .item-div-button-back {
        font-size: 14px;
        padding: 0.5rem 1rem;
    }

    .item-div-button-continue {
        font-size: 14px;
        padding: 0.5rem 1rem;
        margin: 0 1rem;
    }

    .cancel-item-Shipment {
        padding: 1rem;
        font-size: 14px;
    }
}


@media only screen and (max-width: 485px) {
    .ship-item-div {
        margin: 1rem;
        padding: 0rem;
    }

    .ship-item-div h2 {
        font-size: 14px;
    }

    .item-div-content {
        width: 100%;
        display: flex;
        padding: 1rem 2rem;
        align-items: flex-start;
        flex-direction: column;
    }

    .item-box-container {
        display: flex;
        flex-direction: column;
    }

    .item-box-input {
        padding: 1rem;
        width: 100%;
    }

    .item-div-input-row input {
        width: 170px;
    }

    .item-div-input-row p {
        font-size: 14px;
        padding: 0 0.3rem;
    }

    .item-div-simple-text {
        padding: 1rem;

    }

    .item-div-simple-text p {
        font-size: 12px;
    }

    .item-div-simple-text h3 {
        font-size: 14px;
    }

    .shipment-detail-heading {
        padding: 0 1rem;
    }

    .shipment-detail-heading h3 {
        font-size: 24px;
    }

    .shipment-detail-date p {
        padding: 0rem;
    }

    .shipment-detail-heading-discription h3 {
        font-size: 14px;
    }

    .item-div-button {
        padding: 1rem;
    }

    .item-div-button {
        padding: 0.3rem;
    }

    .item-div-button-back {
        font-size: 14px;
        padding: 0.5rem 1rem;
    }

    .item-div-button-continue {
        font-size: 14px;
        padding: 0.5rem;
        margin: 0 0.5rem;
    }

    .cancel-item-Shipment {
        padding: 0.5rem;
        font-size: 12px;
    }

    .item-total-display {
        padding: 1rem;
    }
}