.noPageFound-links {
    padding: 3rem 0;
}

.noPageFound-component-header h1 {
    padding: 1rem;
    text-align: center;
}

.noPageFound-component-header p {
    font-size: 18px;
    text-align: center;
}

.noPageFound-component-body {
    padding: 0 2rem;
    margin: 0 5rem;
}

.noPageFound-list-items {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center;
    justify-content: center; */
    justify-content: left;
    list-style: none;
    margin-top: 2rem;
}

.noPageFound-list {
    width: 300px;
    height: 4rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    /* border-radius: 5px; */
    /* background-color: #eee; */
    border-bottom: 2px solid #dfdbd7;
}

.noPageFound-list a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: blue;
}