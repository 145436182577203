.fixed-navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999999;
}

.extension-navbar {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    /* background-color: var(--primary-dark); */
    background-color: #06214d;
    z-index: 9999;
}

.extension-navbar p {
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
}

.bg-light-blue {
    /* background-color: var(--primary-color); */
    background-color: #fff;
}

.nav-link {
    display: flex !important;
    align-items: center;
    padding: 1 0.7rem !important;
    font-size: 16px !important;
    margin: 0 0.3rem;
}

.navbar {
    padding: 0rem 3rem !important;
}

.dropdown-item {
    font-size: 14px !important;
}

.nav-link {
    /* color: #FF7E00 !important; */
    color: #2857A5 !important;
}

.dropdown-item {
    /* color: #FF7E00 !important; */
    color: #2857A5 !important;
}

.navbar-button {
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 16px;
    /* background-color: #112020; */
    /* background-color: #2857A5; */
    background-color: #ff9401;
    cursor: pointer;
    transition: 0.4s ease;
}

.navbar-button:hover {
    /* background-color: #FF7E00; */
    /* background-color: #ff9401; */
    background-color: #2857A5;
}

.separater-div {
    height: 40px;
    width: 2px;
    background: #112020;
}

.navbar img {
    width: 130px !important;
    height: 90px !important;
}

.navbar-brand {
    padding: 1rem;
    border-radius: 50% !important;
}

.navbar-brand div.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px !important;
    height: 90px !important;
    border-radius: 50%;
    background-color: #fff !important;
}

.navbar-toggler {
    background-color: #fff !important;
}

@media only screen and (max-width: 991px) {
    .separater-div {
        display: none;
    }
}

@media only screen and (max-width: 540px) {
    .extension-navbar p {
        font-size: 10px;
    }
}

@media only screen and (max-width: 485px) {
    .navbar {
        padding: 0 !important;
    }
}