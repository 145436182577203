.signup-form {
    padding: 4rem 13rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-container {
    width: 100%;
    padding: 0 4rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.signup-text-container {
    display: flex;
    padding: 1rem 0;
    padding-right: 1rem;
}

.signup-text-container p {
    padding-right: 0.75rem;
}

.signup-input {
    background: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 90%;
}

.error-text {
    color: red;
}

.signup-form-btn {
    margin: 1rem 0;
    padding: 0.6rem 2rem;
    /* background-color: rgb(255, 208, 0); */
    background-color: #ff9401;
    outline: none;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: 0.4s ease;
}

.signup-form-btn:hover {
    transform: translateX(3px)
}

.signup-form-btn:disabled {
    opacity: 0.37;
}

.signup-container-header {
    font-size: 40px;
    font-weight: 600;
}

.checkbox {
    font-size: 25px;
}

.checkbox-label input {
    margin-right: 8px;
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.loading-spin {
    background: #fff;
}


@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .signup-form {
        padding: 4rem 7rem;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .signup-form {
        padding: 4rem 4rem;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .signup-form {
        padding: 4rem 3rem;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .signup-form {
        padding: 4rem 1rem;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .signup-form {
        padding: 4rem 1rem;
    }

    .signup-container {
        width: 100%;
        padding: 4rem 2rem 1rem 2rem;
    }

    .signup-text-container {
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width: 485px) {
    .signup-form {
        padding: 4rem 0.5rem;
    }

    .signup-container {
        width: 100%;
        padding: 4rem 1rem 1rem 1rem;
    }

    .signup-text-container {
        display: flex;
        flex-direction: column;
    }
}