.description {
    padding: 2rem 6rem 4rem 6rem;
    text-align: center;
}

.description-heading {
    padding: 1rem 0;
    font-size: 28px;
    font-weight: bold;
}

.desc-card {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.d-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    padding: 1rem 0.8rem;
    cursor: pointer;
}

.d-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.d-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.d-image:hover img {
    transform: scale(1.1);
}

.d-card h4 {
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
}

/* @media screen and (max-width:850px) {
    .description {
        margin: 4rem 2rem;
    }

    .desc-card {
        flex-direction: column;
    }

    .d-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }
} */

@media only screen and (min-width: 1024px) and (max-width: 1200px) {}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .d-card p {
        font-size: 14px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .description {
        padding: 4rem 4rem;
    }

    .description-heading {
        font-size: 23px;
    }

    .description-text {
        font-size: 15px;
    }

    .d-card h4 {
        font-size: 1rem;
    }

    .d-card p {
        font-size: 13px;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .description {
        margin: 0rem 4rem;
    }

    .description-heading {
        font-size: 20px;
    }

    .description-text {
        font-size: 13px;
    }

    .desc-card {
        flex-direction: column;
    }

    .d-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .d-image {
        height: 160px;
    }

    .d-card h4 {
        font-size: 1.2rem;
    }

    .d-card p {
        font-size: 12px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .description {
        padding: 2rem 6rem;
    }

    .description-heading {
        font-size: 20px;
    }

    .description-text {
        font-size: 13px;
    }

    .desc-card {
        flex-direction: column;
    }

    .d-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .d-image {
        height: 160px;
    }

    .d-card h4 {
        font-size: 1rem;
    }

    .d-card p {
        font-size: 12px;
    }
}


@media only screen and (max-width: 485px) {
    .description {
        padding: 2rem 2rem;
    }

    .description-heading {
        font-size: 20px;
    }

    .description-text {
        font-size: 13px;
    }

    .desc-card {
        flex-direction: column;
    }

    .d-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .d-image {
        height: 160px;
    }

    .d-card h4 {
        font-size: 1rem;
    }

    .d-card p {
        font-size: 12px;
    }
}