.partner-container {
    padding: 4rem 7rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.partner-container-heading {
    font-size: 30px;
    font-weight: 500;
}

.partner-sub-container {
    padding: 1rem 2.3rem;
    width: 550px;
    height: 330px;
    background: rgba(216, 186, 241, 0.5);
}

.partner-container-text {
    padding: 5px;
    font-size: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1360px) {
    .partner-container {
        padding: 4rem 4rem;
    }

    .partner-sub-container {
        width: 510px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .partner-container {
        padding: 4rem 5rem;
    }

    .partner-sub-container {
        width: 430px;
    }

    .partner-container-heading {
        font-size: 22px;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .partner-container {
        padding: 4rem 5rem;
    }

    .partner-sub-container {
        width: 350px;
        height: 400px;
    }

    .partner-container-heading {
        font-size: 20px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .partner-container {
        padding: 4rem 5rem;
    }

    .partner-sub-container {
        width: 300px;
        height: 490px;
    }

    .partner-container-heading {
        font-size: 20px;
    }


}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .partner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 3rem;
    }

    .partner-sub-container {
        width: 500px;
        height: 320px;
        margin-top: 1rem;
    }

    .partner-container-heading {
        font-size: 18px;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .partner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 1rem;
    }

    .partner-sub-container {
        width: 100%;
        height: 320px;
        margin-top: 1rem;
    }

    .partner-container-heading {
        font-size: 18px;
    }
}


@media only screen and (max-width: 485px) {
    .partner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 1rem;
    }

    .partner-sub-container {
        width: 100%;
        /* height: 400px; */
        padding: 1rem 1rem;
        margin-top: 1rem;
    }

    .partner-container-heading {
        font-size: 18px;
    }

    .partner-container-text {
        font-size: 13px;
    }
}