.company-slider-super-div {
    margin-top: 4rem !important;
    padding: 1rem !important;
}

.company-slider-div-text {
    margin-top: 4rem !important;
    padding: 1rem !important;
    font-size: 1.25rem !important;
}

.company-slider-header {
    font-size: 29px;
}

.company-slider-content {
    font-size: 26px;
    padding-bottom: 1rem;
}

.company-slider-title {
    font-size: 16px;
}

.company-slider-heading {
    font-size: 41px;
    text-align: start;
    font-weight: bold;
}

.company-slider-text {
    font-size: 18px;
}

.company-slider-ceo-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-slider-div-img {
    display: flex;
    gap: 1rem !important;
}

.company-slider-img {
    display: block;
    border-radius: 2rem !important;
    width: 80px;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {}

@media only screen and (min-width: 900px) and (max-width: 991px) {
    .company-slider-div-text {
        margin-top: 1rem !important;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .company-slider-div-text {
        margin-top: 1rem !important;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .company-slider-div-text {
        margin-top: 1rem !important;
    }

    .company-slider-img {
        width: 70px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .company-slider-div-text {
        margin-top: 1rem !important;
    }

    .company-slider-img {
        width: 70px;
    }
}

@media only screen and (max-width: 485px) {
    .company-slider-header {
        font-size: 19px;
    }

    .company-slider-heading {
        font-size: 30px;
    }

    .company-slider-content {
        font-size: 19px;
    }

    .company-slider-text {
        font-size: 14px;
    }

    .company-slider-div-text {
        margin-top: 1rem !important;
    }

    .company-slider-img {
        width: 60px;
    }
}