.addshipment-header {
    margin-top: 7rem;
    padding: 3rem 0rem;
}

.header-first-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;
}

.header-login {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
}

.addshipment-header h1 {
    padding: 1rem 2rem;
}

.header-login i {
    color: rgba(10, 10, 252, 0.7);
}

.header-login p {
    color: rgba(10, 10, 252, 1);
}

.progress-bar {
    width: 100%;
    margin-top: 20px;
}

.progress-bar-div {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    overflow: hidden;
    padding: 1rem;
}

.progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.progress-bar-container-line {
    height: 6px;
    width: 150px;
    border-radius: 10px;
    background-color: rgb(195, 195, 206);
}

.blue-div {
    background-color: rgb(37, 127, 243);
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .progress-bar-container-line {
        height: 6px;
        width: 100px;
    }

    .progress-bar-div {
        padding: 0 1rem;
    }


}


@media only screen and (max-width: 485px) {
    .progress-bar-container-line {
        height: 4px;
        width: 70px;
    }

    .progress-bar-div {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        overflow: hidden;
        padding: 0 1rem;
    }

    .addshipment-header h1 {
        font-size: 22px;
    }

    .header-first-div h2 {
        font-size: 12px;
    }

    .header-first-div {
        padding: 0 2rem;
    }


}