.contact-main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 4rem;
    padding: 1rem;
}

.contact-main-container-iframe {
    width: 100%;
    padding: 2rem;
    height: 500px;

}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .contact-main-container-iframe {
        height: 400px;

    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .contact-main-container-iframe {
        height: 400px;

    }

    .contact-main-container {
        margin: 2rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .contact-main-container-iframe {
        height: 400px;

    }

    .contact-main-container {
        margin: 2rem;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .contact-main-container-iframe {
        height: 300px;

    }

    .contact-main-container {
        margin: 2rem;
    }
}


@media only screen and (max-width: 485px) {
    .contact-main-container-iframe {
        height: 200px;

    }

    .contact-main-container {
        margin: 1rem;
    }
}