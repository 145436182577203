.table-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: scroll;
}

.table-container-shipment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: scroll;
}

.main-table {
    width: auto;
    position: relative;
}

.main-table td {
    border: 1px solid #ddd;
    padding: 3px 5px;
}

.main-table svg {
    padding: 0 8px;
}

.main-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.main-table tr:hover {
    background-color: #ddd;
}

.main-table th {
    padding: 6px 8px;
    text-align: left;
    border: 1px solid #ddd;
    /* background-color: var(--yokan-blue-light-color); */
    background-color: #ff9401;
    font-size: 14px;
    color: white;
}

.table-search-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-search input {
    width: 300px;
    background: #eee;
    border-radius: 10px;
    /* border: 3px solid rgb(0, 162, 255); */
    border: 3px solid #2858A5;
    font-size: 14px;
    padding: 4px 8px;
    margin: 8px 2px;
}

.table-heading h3 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.sortingArrow {
    padding-left: 10px;
}

.table-buttons button {
    background-color: #ddd;
    margin: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in;
}

.table-buttons button:hover {
    transform: translateY(-3px);
}

.table-pagination p {
    font-size: 14px;
}

@media only screen and (min-width: 485px) and (max-width: 600px) {}


@media only screen and (max-width: 485px) {
    .table-search input {
        width: 160px;
        font-size: 14px;
        padding: 4px 8px;
        margin: 8px 2px;
    }

    .table-buttons button {
        background-color: #ddd;
        margin: 0.5rem;
        padding: 5px 10px;
    }

    .table-pagination p {
        font-size: 13px;
    }

    .main-table th {
        font-size: 12px;
    }
}