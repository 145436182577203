.about-us-margin {
    margin: 2rem 0;
}

.company-profile-image {
    height: 380px;
    width: 450px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
        0 5px 15px rgba(0, 0, 0, 0.1);
}

.home-description-text p {
    font-size: 17px;
}

@media only screen and (max-width: 485px) {
    .company-profile-image {
        height: 250px;
        width: 270px;
        object-fit: cover;
        border-radius: 6px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
            0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .home-description-text p {
        font-size: 14px;
    }
}