.partner-job {
    padding: 3rem 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(192, 247, 111, 0.5);
    margin-bottom: 5rem;
}

.partner-job-text {
    font-size: 35px;
    font-weight: bold;

}

.partner-btn {
    margin: 0.5rem;
    padding: 0.4rem 0.9rem;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    border: 2px solid #1888ff;
    color: #1888ff;
    cursor: pointer;
    background-color: var(--primary);
    transition: 0.2s ease-in-out;
}

.partner-btn:hover {
    color: #ee7904;
    border: 2px solid #ee7904;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .partner-job {
        padding: 3rem 5rem;
        display: flex;
        flex-direction: column;
    }

    .partner-job-text {
        font-size: 20px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .partner-job {
        padding: 3rem 5rem;
        display: flex;
        flex-direction: column;
    }

    .partner-job-text {
        font-size: 20px;
    }
}

@media only screen and (min-width: 485px) and (max-width: 600px) {
    .partner-job {
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
    }

    .partner-job-text {
        font-size: 20px;
    }
}


@media only screen and (max-width: 485px) {
    .partner-job {
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
    }

    .partner-job-text {
        font-size: 15px;
    }
}