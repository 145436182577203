.track {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-container {
    background: #eceaea;
    width: 100%;
    padding: 4rem 6rem;
}

.track-container {
    margin: 1rem;
    background-color: #fff;
    width: 90%;
    height: 280px;
    padding: 2rem 2rem;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

.header-login {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
}

.track-heading {
    font-size: 20px;
}

.sub-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-track {
    width: 100%;
    padding: 12px 15px;
    margin: 8px 0;
    background: #eee;
    border: none;
}

.sub-container-sub-text {
    font-size: 12px;
}

.sub-container p {
    font-size: 16px;
}

.track-btn {
    display: flex;
    align-items: center;
    width: 120px;
    margin: 1rem 0;
    padding: 0.6rem 2rem;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 400;
    background-color: #ff9401;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.track-btn .fa-chevron-right {
    padding: 0 5px;
}

.track-btn:hover {
    transform: translateX(4px)
}

/* ----------------track Data-------------------------- */

.tracking-display {
    padding: 2rem 4rem 4rem 4rem;
    background: #eceaea;
    width: 100%;
}

.track-data {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

span {
    margin: 0 !important;
}

.ant-steps .ant-steps-item-description {
    font-size: 13px;
}

.steps-div {
    height: 500px;
    width: 40%;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: -10px 8px 25px -8px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    /* background-color: var(--primary-color) !important; */
    background-color: var(--secondary-light) !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--secondary-light) !important;
    border-color: var(--secondary-light) !important;
}

/* this is the tick */

/* :where(.css-dev-only-do-not-override-p7e5j5).ant-steps .ant-steps-item-icon .ant-steps-icon {
    color: #1677ff;
    color: var(--secondary-light) !important;
} */

.shipment-table {
    height: 400px;
    width: 30%;
    box-shadow: -10px 8px 25px -8px rgba(0, 0, 0, 0.5);
    border-collapse: collapse;
    cursor: pointer;
}

.shipment-table-row {
    background-color: #fff;
}


.shipment-table tbody tr:nth-child(even) {
    /* background-color: rgb(185, 214, 241); */
    background-color: var(--secondary-super-light);
}

td {
    text-align: left;
    padding: 0.3rem 0.7rem;
    font-size: 14px;
}

.shipment-table-td {
    text-align: right;
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

/* -----------------------------------Media query---------------- */


@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .steps-div {
        padding: 1rem;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {

    .tracking-display {
        padding: 2rem 2rem 4rem 2rem;
    }

    .steps-div {
        padding: 1rem;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 14px;
    }


}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .tracking-display {
        padding: 2rem 3rem 4rem 3rem;
    }

    .track-data {
        justify-content: space-between;
    }

    .steps-div {
        padding: 1rem;
        width: 50%;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 14px;
    }

    .shipment-table {
        width: 40%;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .main-container {
        padding: 4rem;
    }

    .track-container {
        width: 100%;
        height: 300px;
    }

    .track-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .tracking-display {
        padding: 2rem 4rem 4rem 4rem;
    }

    .track-data {
        justify-content: space-between;
    }

    .steps-div {
        height: 500px;
        width: 80%;
        padding: 1rem;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 14px;
    }

    .shipment-table {
        height: 400px;
        width: 80%;
        margin-top: 2rem;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .main-container {
        padding: 2rem;
    }

    .track-container {
        width: 100%;
        height: 300px;
        margin: 0rem;
    }

    .track-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tracking-display {
        padding: 2rem;
    }

    .track-data {
        justify-content: space-between;
    }

    .steps-div {
        height: 510px;
        width: 80%;
        padding: 1rem;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 14px;
    }

    .shipment-table {
        height: 400px;
        width: 80%;
        margin-top: 2rem;
    }
}


@media only screen and (min-width: 340px) and (max-width: 485px) {
    .main-container {
        padding: 0.5rem;
    }

    .track-container {
        width: 100%;
        height: 280px;
        margin: 0rem;
        padding: 1rem;
    }

    .track-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tracking-display {
        padding: 1rem;
    }

    .track-data {
        justify-content: space-between;
    }

    .steps-div {
        height: 500px;
        width: 95%;
        padding: 1rem;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 12px;
    }

    .shipment-table {
        height: 400px;
        width: 95%;
        margin-top: 2rem;
    }

    /* .sub-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        } */

    .sub-container p {
        font-size: 12px;
    }
}


@media only screen and (max-width: 340px) {
    .main-container {
        padding: 0.5rem;
    }

    .track-container {
        width: 100%;
        height: 280px;
        margin: 0rem;
        padding: 1rem;
    }

    .track-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tracking-display {
        padding: 1rem;
    }

    .track-data {
        justify-content: space-between;
    }

    .steps-div {
        height: 600px;
        width: 95%;
        padding: 1rem;
    }

    .ant-steps .ant-steps-item-description {
        font-size: 12px;
    }

    .shipment-table {
        height: 400px;
        width: 95%;
        margin-top: 2rem;
    }

    /* .sub-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    } */

    .sub-container p {
        font-size: 12px;
    }
}