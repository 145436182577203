.banner {
    height: 100vh;
    /* width: 100%; */
}

.banner-image {
    object-fit: cover;
    /* height: 100%; */
}

.banner section {
    position: absolute;
    width: 571px;
    height: 406px;
    left: 200px;
    top: 211px;
}

.banner h1 {
    width: 554px;
    height: 213px;
    font-family: 'Rubik';
    font-weight: 700;
    font-size: 60px;
    line-height: 71px;
    margin: 8px 0 0 0;
    color: #FFFFFF;
}

.banner p {
    font-family: 'Krub';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 151.52%;
    margin: 6px auto 24px;
    color: #FFFFFF;
}

.banner-btn {
    background: linear-gradient(94.06deg, #FFB629 -1.21%, #FFDA56 58.66%, #FFD7A6 116.84%);
    width: 194px;
    height: 60px;
    font-family: 'Krub', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 135.02%;
    color: #23212A;
    margin-top: 0.5rem;
    border: none;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

/* background-color: #FFFFFF; */

.dark {
    background-color: #1F2A69;
    height: 30px;
    width: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 100% 100%;
}

.banner-btn:hover {
    transform: translateY(-4px);
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .banner section {
        width: 431px;
        height: 340px;
        left: 130px;
        top: 221px;
    }

    .banner h1 {
        width: 400px;
        height: 163px;
        font-size: 40px;
        line-height: 51px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }
}


@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .banner section {
        width: 431px;
        height: 340px;
        left: 130px;
        top: 221px;
    }

    .banner h1 {
        width: 400px;
        height: 163px;
        font-size: 40px;
        line-height: 51px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px) {
    .banner section {
        width: 401px;
        height: 310px;
        left: 130px;
        top: 271px;
    }

    .banner h1 {
        width: 400px;
        height: 103px;
        font-size: 30px;
        line-height: 41px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }

    .banner-btn {
        width: 164px;
        height: 50px;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .banner section {
        width: 360px;
        height: 310px;
        left: 80px;
        top: 271px;
    }

    .banner h1 {
        width: 350px;
        height: 103px;
        font-size: 30px;
        line-height: 41px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }

    .banner-btn {
        width: 164px;
        height: 50px;
    }

    .dark {
        height: 25px;
        width: 34px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .banner section {
        width: 260px;
        height: 310px;
        left: 80px;
        top: 221px;
    }

    .banner h1 {
        width: 250px;
        height: 120px;
        font-size: 30px;
        line-height: 31px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }

    .banner-btn {
        width: 144px;
        height: 50px;
        font-size: 16px;
    }

    .dark {
        height: 20px;
        width: 29px;
    }
}


@media only screen and (max-width: 485px) {
    .banner section {
        width: 200px;
        height: 310px;
        left: 40px;
        top: 221px;
    }

    .banner h1 {
        width: 200px;
        height: 100px;
        font-size: 20px;
        line-height: 31px;
        margin: 8px 0 0 0;
        color: #FFFFFF;
    }

    .banner p {

        font-weight: 500;
        font-size: 14px;
        line-height: 131.52%;
        color: #FFFFFF;
    }

    .banner-btn {
        width: 124px;
        height: 45px;
        font-size: 14px;
    }

    .dark {
        height: 15px;
        width: 24px;
    }
}